@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;700&display=swap");

.courses-button-menu {
  height: 60px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #9d9d9d;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease 0s;
  /* font-family: "Inter";
	font-style: normal;
	font-size: 15px;
	font-weight: 500;
	line-height: 22px; */
  /* or 150% */
  text-align: center;

  color: #212832;
}

.courses-button-menu:hover,
.courses-button-menu-active {
  border-color: #005d92;
  color: var(--bs-primary-blue);
}

.menu-span-button {
  height: 13px;
  width: 13px;
  background: var(--bs-primary-blue);
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  z-index: 11;
  margin: 0 7px;
}

.menu-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 43px;
}

.swiper-custom-pagination span {
  height: 13px;
  width: 13px;
  background: var(--bs-primary-blue);
}

.header-container-course {
  width: 100%;
  height: 221px;
  background-color: #eef6fb;
}

.course-color-active {
  color: var(--bs-primary-blue);
}

/* .course-list-nav {

} */

.course-list-nav-active {
  /* transform: scaleY(1);
	transform-origin: top center; */
  animation-name: vanish;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  transform-origin: top;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes vanish {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}

.content {
  background-color: coral;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* Add the animation properties */
  animation-name: clipPathAnimation;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  overflow: hidden;
}
@keyframes clipPathAnimation {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    max-height: 100%;
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
    max-height: 0;
  }
}

.loading-spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--bs-primary-blue);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.shopping-cart-wrapper {
  border: 2px soild green;
  min-height: 90vh;
  width: 100%;
  padding: 200px 0;
}
.shopping-cart-header {
  background-color: var(--bs-background-blue);
  width: 100%;
  height: 100%;
  padding: 20px 0 70px 0;
}
.shopping-cart-box {
  width: 100%;
  height: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.cart-items {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cart-items-quantity {
  width: 70%;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #2c2c2c;
  padding: 10px 0;
}
.cart-items-summary {
  width: 30%;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #2c2c2c;
  padding: 10px 0;
}
.cart-headers {
  width: 100%;
}
.cart-items .cart-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
  border-top: 2px solid #2c2c2c;
}
.cart-item-image {
  width: 130px;
  height: 85px;
  border-radius: 5px;
}
.cart-item-details {
  width: 55%;
  height: 85px;
  padding: 0 30px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.cart-item-title {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #2c2c2c;
  font-size: 16px;
}
.cart-item-author {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #2c2c2c;
  font-size: 14px;
}
.cart-item-duration {
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.cart-item-length,
.cart-item-lectures {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #2c2c2c;
  font-size: 14px;
}
.cart-item-delete {
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #2c2c2c;
  background-color: transparent;
  position: relative;
}
.cart-item-delete::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -6.5px;
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: content-box;
  border: 2px solid #005d92;
  border-radius: 10px;
  display: none;
}
.cart-item-delete:focus-visible::after {
  display: block;
}
.cart-item-actions {
  display: flex;
  justify-content: space-between;
  width: 25%;
  align-items: flex-start;
}
.cart-item-price {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.actual-price {
  color: #005d92;
  font-size: 18px;
  font-weight: bold;
}
.former-price {
  color: #2c2c2c;
  font-size: 15px;
  font-weight: normal;
  text-decoration: line-through;
}
.cart-price {
  width: 30%;
  height: 100%;
  padding: 0 40px;
}
.price-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #2c2c2c;
}
.actual-summary-price {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #2c2c2c;
}
.former-summary-price {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: normal;
  color: #2c2c2c;
  text-decoration: line-through;
}
.checkout-link {
  width: 100%;
  padding: 10px 0;
  background-color: #005d92;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  text-align: center;
  margin: 20px 0;
  position: relative;
}
.checkout-link::after {
  content: "";
  position: absolute;
  top: -7px;
  left: -6.5px;
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: content-box;
  border: 2px solid #2c2c2c;
  border-radius: 10px;
  display: none;
}
.checkout-link:focus::after {
  display: block;
}
.checkout-link:hover {
  color: white;
}
.suggested-courses {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
}
.suggested-header {
  width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  color: #2c2c2c;
  font-size: 25px;
}
.suggested-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.focusable-course {
  position: relative;
}
.focusable-course:focus-visible {
  outline: none;
}
.focusable-course::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 15px;
  left: 0;
  padding: 5px;
  border: 2px solid #005d92;
  border-radius: 10px;
  display: none;
}
.focusable-course:focus-visible::after {
  display: block;
}
@media (max-width: 992px) {
  .shopping-cart-box {
    flex-direction: column;
  }
  .cart-items {
    width: 100%;
  }
  .cart-price {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .cart-item-duration {
    width: 100%;
  }
  .cart-item-details {
    height: 110px;
  }
}
@media (max-width: 575px) {
  .cart-items {
    align-items: center;
  }
  .cart-items .cart-item {
    flex-direction: column;
    padding: 20px 5px;
    width: 95%;
  }
  .cart-item-image {
    width: 100%;
    height: auto;
  }
  .cart-item-details {
    width: 100%;
    height: auto;
    padding: 0;
    margin-top: 10px;
  }
  .cart-item-title {
    font-size: 25px;
  }
  .cart-item-author {
    font-size: 20px;
  }
  .cart-item-length {
    font-size: 18px;
  }
  .cart-item-lectures {
    font-size: 18px;
  }
  .cart-item-actions {
    width: 100%;
    margin: 10px 0;
  }
  .cart-item-price {
    align-items: flex-end;
  }
  .cart-item-delete {
    font-size: 20px;
  }
  .actual-price {
    font-size: 25px;
  }
  .former-price {
    font-size: 20px;
  }
  .cart-price {
    width: 95%;
  }
  .shopping-cart-box {
    align-items: center;
  }
  .suggested-courses {
    align-items: center;
  }
  .suggested-header {
    padding-left: 10px;
  }
}
.privacy {
  min-height: 90vh;
  width: 100%;
  padding: 200px 0;
}

.profile_element_container {
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatar-box {
  width: 60px;
  /* flex-shrink: 0; */
  border-radius: 10px;
  height: 60px;
  background: #005d92;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_element_container p {
  font-size: 18px;
  font-weight: 500;
  color: #212832;
}

.profile_element_container p:hover {
  color: var(--bs-primary-blue);
  transition: 500ms;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.profile_element_container img {
  width: 40px;
  height: 40px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  border-radius: 5%;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.text-wrapper-footer {
  width: 225px;
  margin-top: 38px;
}
.text-wrapper-footer .text-footer {
  color: #2c2c2c;
  font-size: 15px;
  font-family: Inter;
  line-height: 27px;
  text-align: left;
}

.notification-menu {
  right: 0;
  display: none;
  position: absolute;
  top: 70px;
  width: 456px;
  border-radius: 10px;
  border: 1px solid #9d9d9d;
  background: #fff;
}

.shop-cart-menu {
  right: 0;
  display: none;
  position: absolute;
  top: 70px;
  width: 428px;
  height: 242px;
  border-radius: 10px;
  border: 1px solid #9d9d9d;
  background: #fff;
  padding-top: 24px;
  padding-left: 33px;
}

.user-menu {
  display: none;
  position: absolute;
  top: 70px;
  width: 188px;
  /* height: 213px; */
  padding-bottom: 40px;
  border-radius: 10px;
  border: 1px solid #9d9d9d;
  background: #fff;
  padding-top: 24px;
  padding-left: 33px;
  right: 0;
}

.quiz-answer-li {
  color: #000;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.quiz-li-circle {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid #2c2c2c;
  margin-right: 30px;
}

.back-quiz-button {
  width: 114px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #2c2c2c;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  text-align: center;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 58px;
}

.next-quiz-button {
  width: 114px;
  height: 60px;
  border-radius: 10px;
  background: #005d92;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 58px;
}
.quiz-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 40%;
  margin-top: 30px;
}

.quiz-li-circle-active {
  background-color: #000;
}

.menu-visible {
  display: inline-block;
}

.icon-header-item {
  position: relative;
}

.icon-header-item::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -6.5px;
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: content-box;
  border: 2px solid #005d92;
  border-radius: 10px;
  display: none;
}
.icon-header-item:focus-visible::after {
  display: block;
}

.preview-element-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
}

.preview-element-wrapper::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 19px;
  right: 23px;
  height: 1px;
  background: #000000;
}

.image-previev-wrapper {
  width: 132px;
  height: 86px;
  margin-right: 10px;
}

.text-previev-wrapper {
  width: 272px;
  /* width: 200px; */
  display: flex;
  flex-direction: column;
}

.image-previev {
  width: 100%;
  height: 100%;
}

.text-previev-title {
  color: #2c2c2c;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 22.4px;
}

.text-previev-author {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 21px;
}

.text-previev-price {
  color: #005d92;
  font-family: Inter;
  font-weight: bold;
  line-height: 27px;
  margin-right: 5px;
  font-size: 18px;
}

.text-previev-sale {
  color: #2c2c2c;
  font-size: 14px;
  font-family: Inter;
  line-height: 21px;
  color: #2c2c2c;
  font-weight: normal;
  text-decoration: line-through;
}

.preview-item-price {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.summary-price-preview {
  color: #2c2c2c;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  line-height: 26.4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.go-to-shop-cart-button {
  margin-top: 8px;
  outline: none;
  border-radius: 10px;
  background: #005d92;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: Inter;
  font-weight: 700 !important;
  line-height: 27px;
  color: #fff !important;
  width: 308px;
  height: 50px;
  border: none;
}

.contact-single-element {
  height: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
}

.text-contact-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  justify-content: space-between;
  padding-left: 30px;
}

.text-contact-wrapper-title {
  color: #005d92;
  font-size: 14px;
  font-family: Inter;
  line-height: 16.8px;
}

.text-contact-wrapper-content {
  color: #2c2c2c;
  font-size: 22px;
  font-family: Inter;
  font-weight: 500;
  line-height: 39.6px;
}

.font-class-headermenu {
  font-family: var(--bs-body-font-family);
}

.password-reset-wrapper {
  border: 2px solid green;
  min-height: 90vh;
  width: 100%;
  padding: 200px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-reset-box {
  width: 400px;
}

.password-reset-title {
  font-size: 24px;
  font-family: Inter;
  font-weight: 700;
  color: #2c2c2c;
  margin-bottom: 20px;
}

.password-reset-text {
  font-size: 18px;
  font-family: Inter;
  color: #2c2c2c;
  margin-bottom: 44px;
}

.password-reset-input {
  width: 100%;
  height: 60px;
  padding: 19.5px 121px 19.5px 26px;
  border-radius: 10px;
  border: 1px solid #9d9d9d;
  background: #fff;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c2c2c;
  font-size: 15px;
  font-family: Inter;
}

.password-reset-input:focus {
  border: 1px solid #005d92;
  outline: none;
}

.password-reset-button {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 21px 50px;
  justify-content: center;
  align-items: center;
  background: #005d92;
  border-radius: 10px;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  line-height: 60px;
}

.agenda-container {
  width: 100%;
  margin-top: 32px;
  background: #eef6fb;
}

.error-text {
  color: #b2282d;
  font-size: 12px;
  font-family: Inter;
  letter-spacing: -0.48px;
  padding-left: 5px;
}

.user-menu-element-text:hover {
  color: #005d92;
}

.user-menu-element-text {
  color: #2c2c2c;
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  line-height: 26.4px;
}
.nav-element {
  position: relative;
}
.nav-element::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -5px;
  width: calc(100% + 10px);
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  border: 2px solid #005d92;
  display: none;
}
.nav-element:focus::after {
  display: block;
}

.title-mark {
  color: #005d92;
  font-size: 100px;
  font-family: Inter;
  font-weight: 700;
  line-height: 27px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.user-avatar-opinion {
  border-radius: 55px;
  background: #2c2c2c;
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 21px;
  font-family: Inter;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}

.user-avatar-instructor {
  border-radius: 50%;
  background: #2c2c2c;
  width: 132px;
  height: 132px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 21px;
  font-family: Inter;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 59px;
}
.feedback-container {
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 111;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.stars-feedback-wrapper {
}

.feedback-text-area {
  border-radius: 10px;
  border: 1px solid #005d92;
  width: 696px;
  height: 165px;
  flex-shrink: 0;
  resize: none;
  padding: 10px;
  color: #555;
  font-size: 16px;
  font-family: Inter;
  line-height: 27px;
}

.send-feedback-button {
  width: 200px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #005d92;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  line-height: 58px;
  border: none;
}

.send-feedback-aske-me {
  border-radius: 10px;
  border: 1px solid #2c2c2c;
  background: #fff;
  display: flex;
  height: 45px;
  width: 183px;
  color: #2c2c2c;
  text-align: center;
  font-size: 15px;
  font-family: Inter;
  font-weight: 500;
  line-height: 41px;
  justify-content: center;
  align-items: center;
}

.medals-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.single-medal-box {
  width: 140px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px 0;
}

.medal-title {
  color: #2c2c2c;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  height: 30px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.medal-img {
  width: 75.7px;
  height: 83.3px;
  flex-shrink: 0;
}

@media screen and (max-width: 600px) {
  .medals-wrapper {
    flex-direction: column;
  }
  .single-medal-box {
    margin: 40px 0;
  }
}
